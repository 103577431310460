<template>
  <router-link class="user-component" :to="userLink">
    <a-avatar :size="40" :src="avatar">
      <a-icon slot="icon" type="user"/>
    </a-avatar>

    <div class="name">
      <span> {{ name }} </span>
    </div>

    <div class="country">
      <img :src="country" alt=""/>
    </div>
 </router-link>
</template>

<script>

export default {
   name: 'User',
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  computed: {
    id () {
      return this.user.id
    },
    name () {
      return this.user.name
    },
    avatar () {
      return this.user.photo
    },
    country () {
      return this.user?.country.icon
    },
    userLink() {
      const isChat = this.$route.name === 'new-message'
      const chatLink = { name: 'personal-chat', params: { userID: this.id } }
      const accountLink = { name: 'account', params: { userID: this.id } }
      return isChat ? chatLink : accountLink
    }
  }
}
</script>
